import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ProjectsComponent } from '../projects/projects.component';
import { StateButtonsComponent } from '../../shared/state-buttons/state-buttons.component';

import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'project-curation',
  templateUrl: './project-curation.component.html',
})
export class ProjectCurationComponent implements OnInit {
  position = 'after';

  // used to trigger saving of project linking changes before refreshing the list of projects in the series
  @ViewChild(StateButtonsComponent) stateButtons!: StateButtonsComponent;

  // used to trigger refresh of the list of projects in the project series
  @ViewChild(ProjectsComponent) projects!: ProjectsComponent;

  // project id in the route
  projid: string | null = '';
  // flag that the project id was invalid or the user doesn't have access
  invalidID = false;
  // project object that is displayed in the page (and edited if the user make modifications)
  project: any = { projdoc: {}, workflow: [], abstracts: [] };
  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = { projdoc: {}, workflow: [], abstracts: [] };

  // determine whether the page should be modifiable
  isCurator = false;

  // list of project series options
  projectSeriesList: any[] = [];
  // input for the object form of the selected project series...once one is selected, then we populate the
  // the project.seriestag value and display that
  seriesInput: any;

  // define workflow editable-table columns
  workflowColumns: any[] = [
    { key: 'step', name: 'Step #', class: 'width10-percent', maxlength: 20 },
    { key: 'descrip', name: 'Description', class: 'width30-percent', maxlength: 1000 },
    { key: 'equip', name: 'Equipment (general)', class: 'width20-percent', maxlength: 200 },
    { key: 'data', name: 'Data Collected (general)', class: 'width30-percent', maxlength: 1000 },
  ];

  // list of methods terms that should be disabled for adding abstracts
  abstractMethods: any[] = [];
  // used for the methodology lookup
  methodSearch: any = {};
  // columns for the edit abstracts table
  abstractsColumns: any[] = [
    { key: 'method', name: 'Method', class: 'width15-percent', disabled: true },
    { key: 'abstract', name: 'Abstract', class: 'width40-percent', maxlength: 1000 },
    { key: 'archive_keywords', name: 'Archive Keywords', class: 'width30-percent', maxlength: 2000 },
  ];

  // hide entry fields until project is done loading
  loadingProject = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public router: Router,
  ) {}

  ngOnInit() {
    // oninit of the component, load the project from the id in the route (this component requires an id,
    // unlike proj-details, which can be used to create a new project if one isn't passed in through the route)
    this.projid = this.route.snapshot.paramMap.get('id') || null;
    if (Number(this.projid)) {
      this.getProject();
      this.http.get<any[]>(environment.securedURLs.sip + 'projects/series').subscribe((result) => {
        this.projectSeriesList = result;
      });
    } else {
      this.invalidID = true;
    }
  }

  // get the project from the api
  getProject() {
    if (this.projid) {
      this.loadingProject = true;
      this.projectService.getProject(this.projid).subscribe(
        (data) => {
          this.invalidID = data.table_form;
          if (!this.invalidID) {
            this.project = data;
            // I'm not sure if setting both to data would result in them just pointing to the same object,
            // so I'm making sure that it's a deep copy just in case
            this.dbProject = JSON.parse(JSON.stringify(data));
            this.updateAbstractMethods();
          }
          this.loadingProject = false;
        },
        () => {
          this.invalidID = true;
          this.loadingProject = false;
        },
      );
    }
  }

  // this is used as the disableList for the methods lookup that is
  updateAbstractMethods() {
    this.abstractMethods = [];
    for (const abstract of this.project.abstracts) {
      this.abstractMethods.push({ term: abstract.method });
    }
  }

  // used to update the list of projects in the series
  // after saving any changes to the project series info for this project
  updateSeriesProjects() {
    this.stateButtons.saveProject().subscribe(() => {
      this.projects.getProjects();
    });
  }

  /**
   * On change of the method lookup, add the method to the abstracts editable
   * table and add it to the list to disable in the lookup.
   *
   * @param {any} method: method dict
   * @returns {boolean}: true to clear the lookup, false to not
   */
  onMethodChange(method: any) {
    if (method.term) {
      // just in case, make sure that this method isn't already in the list
      let methodFound = false;
      for (const abstract of this.project.abstracts) {
        if (abstract.method === method.term) {
          methodFound = true;
          break;
        }
      }
      if (!methodFound) {
        this.project.abstracts.push({ method: method.term });
      }
      this.abstractMethods.push(method);
      return true;
    }
    return false;
  }
}
